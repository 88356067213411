import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routes = [{
  path: '/',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'main',
    component: () => import(/* webpackChunkName: "main" */ '@/pages/EITMain.vue'),
    meta: { requiresAuth: true } // 添加一个元字段，指示该路由需要身份验证
  }]
}, {
  path: '/signin',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/EITSignIn.vue')
  },
  {
    path: '/signup',
    component: () => import(/* webpackChunkName: "register" */ '@/pages/EITSignUp.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/forgetPassword',
    component: () => import(/* webpackChunkName: "forgetPassword" */ '@/pages/EITForgetPassword.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/resetPassword/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "ResetPassword" */ '@/pages/ResetPassword'),
    meta: { requiresAuth: false }
  },
  {
    path: '/termofservice',
    component: () => import(/* webpackChunkName: "testP" */ '@/pages/EITTerm.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/codes',
    component: () => import(/* webpackChunkName: "invitationcode" */ '@/pages/EITInvitationCode.vue'),
    meta: { requiresAuth: true }
  }, {
  path: '*',
  component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
  children: [{
    path: '',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue')
  }]
}]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  var user = Vue.prototype.$getCookie('user');
  const isLoggedIn = user != null; /* 在这里检查用户是否已登录 */
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 如果访问需要身份验证的页面
    if (isLoggedIn) {
      // 用户已登录，允许访问
      return next();
    } else {
      // 用户未登录，重定向到登录页面
      return next('/signin');
    }
  } else {
    // 不需要身份验证的页面，直接允许访问
    return next();
  }  
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
